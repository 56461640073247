<template>
  <nav class="text-2xl md:text-base md:font-medium">
    <ul
      class="flex flex-col md:flex-row text-white bg-primary-500 md:pr-1 gap-8 md:gap-0 pb-24 md:pb-0 transition-all ease-in-out duration-250"
    >
      <li>
        <nuxt-link
          :to="newsroomUrl"
          class="h-[50px] flex items-center gap-1 hover:bg-primary-600 transition-all ease-in-out duration-250 px-3"
        >
          {{ $texts('header.newsroom', 'Newsroom') }}
          <SpriteSymbol name="newspaper" class="size-6 md:size-5" />
        </nuxt-link>
      </li>
      <li>
        <nuxt-link
          :to="contactUrl"
          class="h-[50px] flex items-center gap-1 hover:bg-primary-600 transition-all ease-in-out duration-250 px-3"
        >
          <span
            :class="{
              'sr-only lg:not-sr-only': !isMenuOpen,
            }"
          >
            {{ $texts('header.contact', 'Kontakt') }}
          </span>
          <SpriteSymbol name="paperplane" class="size-6 md:size-5" />
        </nuxt-link>
      </li>
      <li>
        <nuxt-link
          :to="{ name: 'search' }"
          class="h-[50px] flex items-center gap-1 hover:bg-primary-600 transition-all ease-in-out duration-250 px-3"
        >
          {{ $texts('header.search', 'Suche') }}
          <SpriteSymbol name="search" class="size-6 md:size-5" />
        </nuxt-link>
      </li>
    </ul>
  </nav>
</template>

<script lang="ts" setup>
defineProps<{
  isMenuOpen: boolean
}>()

const { $texts } = useEasyTexts()
const initData = await useInitData()
const newsroomUrl = initData.value.globalConfig.newsroomUrl?.uri?.path
const contactUrl = initData.value.globalConfig.contactUrl?.uri?.path
</script>
