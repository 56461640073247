import type { InitData } from '~/composables/useInitData'
import type { MenuLinkTreeFragment } from '#graphql-operations'

export function isLinkTreeActive(
  tree: MenuLinkTreeFragment & { subtree?: Array<MenuLinkTreeFragment> },
  currentRoutePath: string,
): boolean {
  if (tree.link.url?.path === currentRoutePath) {
    return true
  }

  if (tree.subtree) {
    return tree.subtree.some((subtreeEl) =>
      isLinkTreeActive(subtreeEl, currentRoutePath),
    )
  }

  return false
}

export async function useMainMenuLinks() {
  const initData: Ref<InitData> = await useInitData()
  const currentRoute = useRoute()

  const gastronomyLinks = computed(() => {
    // Last three elements are always considered gastronomy.
    // Do not add any elements _after_ the gastronomy links,
    // or this will start to behave weirdly.
    return initData.value.mainMenuLinks.slice(-2)
  })

  const nonGastronomyLinks = computed(() => {
    // Last three elements are always considered gastronomy.
    // Do not add any elements _after_ the gastronomy links,
    // or this will start to behave weirdly.
    return initData.value.mainMenuLinks.slice(0, -2)
  })

  const currentActiveMainLink = computed(() => {
    return initData.value.mainMenuLinks.find((l) =>
      isLinkTreeActive(l, currentRoute.path),
    )
  })

  const activeGastronomyLink = computed(() => {
    return gastronomyLinks.value.find((l) =>
      isLinkTreeActive(l, currentRoute.path),
    )
  })

  const activeLocalLink = computed(() => {
    return currentActiveMainLink.value?.subtree.find((l) =>
      isLinkTreeActive(l, currentRoute.path),
    )
  })

  return {
    gastronomyLinks,
    nonGastronomyLinks,
    currentActiveMainLink,
    activeGastronomyLink,
    activeLocalLink,
  }
}
