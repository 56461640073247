<template>
  <div>
    <PageHeader
      @menu:open="menuOpen"
      @menu:close:start="menuCloseStart"
      @menu:close:finished="menuCloseFinish"
    />

    <div
      :class="{
        'pt-[100px]':
          (isMenuOpen || !hasMenuFinishedClosing) && viewport.isLessThan('lg'),
      }"
    >
      <NuxtPageDependency>
        <Breadcrumb v-if="showBreadcrumb" :links="breadcrumb" />
      </NuxtPageDependency>

      <div>
        <ClientOnly>
          <DrupalMessages v-if="!isEditing" />
        </ClientOnly>

        <slot />
      </div>
    </div>

    <NuxtPageDependency>
      <PageFooter :business-area-key="businessAreaKey" />
    </NuxtPageDependency>
  </div>
</template>

<script setup lang="ts">
const route = useRoute()
const drupalUser = useDrupalUser()
const language = useCurrentLanguage()
const breadcrumb = useBreadcrumbLinks()
const businessAreaKey = useBusinessArea()
const viewport = useViewport()

const isEditing = computed(
  () =>
    !!(route.query.blokkliEditing || route.query.blokkliPreview) &&
    drupalUser.value.accessToolbar,
)

const showBreadcrumb = computed(
  () =>
    route.meta.hideBreadcrumb !== true && route.meta.name !== 'drupal-route',
)

const isMenuOpen = ref(false)
const hasMenuFinishedClosing = ref(true)

useHead({
  htmlAttrs: {
    lang: language.value,
    class: computed(() => {
      if (businessAreaKey.value === 'SV_HOTEL') return 'is-hotel'
      return ''
    }),
  },
  bodyAttrs: {
    class: computed(() => {
      if (isMenuOpen.value) return 'overflow-y-hidden'
      return ''
    }),
  },
})

function menuOpen() {
  isMenuOpen.value = true
}

function menuCloseStart() {
  if (viewport.isLessThan('lg')) {
    isMenuOpen.value = false
    hasMenuFinishedClosing.value = false
  }
}

function menuCloseFinish() {
  if (viewport.isLessThan('lg')) {
    hasMenuFinishedClosing.value = true
  }
}
</script>
