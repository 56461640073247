<template>
  <header
    ref="container"
    class="grid page-header-grid transition-all ease-in-out duration-250 top-0 z-10"
    :class="{
      '-translate-y-10 md:translate-y-[-50px]':
        isHeaderShiftedUp && !isMenuOpen,
      'max-h-[120px] md:max-h-screen !overflow-y-hidden md:!overflow-y-visible':
        !isMenuOpen,
      'overflow-y-auto max-h-screen': isMenuOpen,
      'fixed w-full': isGlobalSticky,
      sticky: !isGlobalSticky,
    }"
  >
    <PageHeaderLogo :is-sticky="isGlobalSticky" class="grid-area-logo" />
    <PageHeaderLanguageSwitch
      ref="langSwitch"
      :is-sticky="isGlobalSticky"
      :is-header-shifted-up="isHeaderShiftedUp"
      :class="{
        hidden: languageLinks.length === 0,
      }"
    />
    <PageHeaderGlobalMenu
      v-if="showMenu"
      ref="globalMenu"
      class="grid-area-global"
    />
    <PageHeaderContextMenu
      v-if="showMenu"
      :is-menu-open="isMenuOpen"
      class="grid-area-context bg-primary-500"
      :class="{
        'md:pr-4': languageLinks.length === 0,
      }"
    />

    <Transition name="local-menu" mode="out-in">
      <PageHeaderLocalMenu
        :key="currentActiveMainLink?.link.label"
        class="grid-area-local"
        :is-menu-open="isMenuOpen"
        :is-header-shifted-up="isHeaderShiftedUp"
        :label="currentActiveMainLink?.link.label"
        :links="currentActiveMainLink?.subtree"
        @menu:open:from-local="toggleMenu"
        @menu:close:from-local="closeMenu"
      />
    </Transition>
  </header>
</template>

<script lang="ts" setup>
import type { ComputedRef } from 'vue'
import { type PageHeaderGlobalMenu, PageHeaderLocalMenu } from '#components'
import { checkIfRefIsNotNull } from '~/helpers/checkIfRefIsNotNull'

type LanguageLink = {
  code: string
  active: boolean
  to: string
}

const route = useRoute()
const globalMenu = ref<typeof PageHeaderGlobalMenu | null>(null)
const container = ref<HTMLElement | null>(null)
const isHeaderShiftedUp = ref(false)
const isGlobalSticky = ref(false)
const isMenuOpen = ref(false)
const lastScrollY = ref(0)
const viewport = useViewport()
const languages: ComputedRef<LanguageLink[]> = useLanguageLinks()
const currentLanguage = useCurrentLanguage()
const languageLinks = computed(() => {
  return languages.value.filter((l) => l.code !== currentLanguage.value)
})

let timeout: number | null = null

watch(() => route.path, closeMenu)

const { currentActiveMainLink } = await useMainMenuLinks()

// Fixes SV-177: Mobile menu.
const showMenu = computed(() => {
  return !viewport.isLessThan('md') || isMenuOpen.value
})

const emit = defineEmits([
  'menu:open',
  'menu:close:start',
  'menu:close:finished',
])

function openMenu() {
  isMenuOpen.value = true
  isGlobalSticky.value = true
  isHeaderShiftedUp.value = false
  emit('menu:open')
}

function closeMenu() {
  if (timeout) {
    clearTimeout(timeout)
  }
  emit('menu:close:start')
  isMenuOpen.value = false
  if (checkIfRefIsNotNull<HTMLElement>(container)) {
    // We need to manually reset the scroll position inside the menu container,
    // so it doesn't
    container.value.scrollTop = 0
  }

  timeout = window.setTimeout(() => {
    emit('menu:close:finished')
    isGlobalSticky.value = false
  }, 250)
}

function toggleMenu() {
  if (isMenuOpen.value) {
    closeMenu()
  } else {
    openMenu()
  }
}

function shiftMenuYPositionOnScrollPos() {
  if (isGlobalSticky.value) {
    return
  }

  const currentScrollY = window.scrollY
  // Calculate the difference between the last scroll position.
  const diff = currentScrollY - lastScrollY.value

  if (currentScrollY < 300) {
    // Near the top of the page always show the header.
    isHeaderShiftedUp.value = false
  } else if (diff > 5) {
    // Scrolling direction is downwards.
    isHeaderShiftedUp.value = true

    if (
      !isMenuOpen.value &&
      checkIfRefIsNotNull<typeof PageHeaderGlobalMenu>(globalMenu)
    ) {
      globalMenu.value.closeGastronomyDropdown()
    }
  } else if (diff < -5) {
    // Scrolling direction is upwards.
    isHeaderShiftedUp.value = false
  }

  lastScrollY.value = currentScrollY
}

watch(viewport.breakpoint, (newValue: string) => {
  if (newValue === 'md' || newValue === 'lg' || newValue === 'xl') {
    isMenuOpen.value = false
  }
})

onMounted(() => {
  // Setting passive prevents performance issues.
  window.addEventListener('scroll', shiftMenuYPositionOnScrollPos, {
    passive: true,
  })
})

onBeforeUnmount(() => {
  window.removeEventListener('scroll', shiftMenuYPositionOnScrollPos)
})
</script>

<style lang="postcss">
.page-header-grid {
  grid-template:
    'logo search' theme(space.10)
    'local local' 1fr
    'global global' auto
    'context context' auto
    / 1fr auto;

  @screen md {
    grid-template:
      'logo global context search' 50px
      'local local local local' auto
      / auto 1fr auto auto;
  }
}

.grid-area-logo {
  grid-area: logo;
}

.grid-area-global {
  grid-area: global;
}

.grid-area-context {
  grid-area: context;
}

.grid-area-search {
  grid-area: search;
}

.grid-area-local {
  grid-area: local;
}

.local-menu-enter-active,
.local-menu-leave-active {
  @apply transition duration-300 ease-in-out;
}

.local-menu-enter-from,
.local-menu-leave-to {
  @apply opacity-0;
}
</style>
